body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #282c34;
}

/* .App {

} */

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
  margin: 10px;
}

.App-body {
  color: white;
  margin: 10px;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  color: white;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
}

