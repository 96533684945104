body {
  background-color: #282c34;
}

/* .App {

} */

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
  margin: 10px;
}

.App-body {
  color: white;
  margin: 10px;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  color: white;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
}
